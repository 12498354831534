<template>
  <!-- 
    画面: オプション編集
    用途: オプションを更新または削除する
   -->
  <div>
    <!-- #region Main -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title">{{ $t("optionEditPage.pageTitle") }}</strong>
            <!-- <strong class="card-title">{{ $t("facilityEditPage.pageTitle") }}</strong> -->
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form">
              <!-- #region 入力フィールド -->

              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("optionEditPage.name")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.name" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("optionEditPage.name"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.maxLength"
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("optionEditPage.name"),
                        vMax: $v.edit.name.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"><strong>{{
                    $t("optionEditPage.description")
                  }}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    v-model="edit.description"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.description.$dirty &&
                      !$v.edit.description.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("roomCategoryEditPage.description"),
                        vMax: $v.edit.description.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #region 単価 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("optionEditPage.price")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.price" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.price.$dirty && !$v.edit.price.required
                    "
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("optionEditPage.price"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.price.$dirty && !$v.edit.price.numeric
                    "
                  >
                    {{
                      $t("validations.numeric", {
                        vName: $t("optionEditPage.price"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 単価 -->

              <!-- #region 基本提供数 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{
                    $t("optionEditPage.inventory")
                  }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.inventory" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.inventory.$dirty && !$v.edit.inventory.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("optionEditPage.inventory"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.inventory.$dirty && !$v.edit.inventory.numeric
                    "
                  >
                    {{
                      $t("validations.numeric", {
                        vName: $t("optionCreatePage.inventory"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 基本提供数 -->

              <!-- #region オプション分類 -->
              <CRow>
                <CCol class="label"><strong>{{ $t("optionEditPage.kind") }}</strong></CCol>
                <CCol>
                  <div class="form-inline">
                    <CInputCheckbox
                      class="mx-1"
                      v-for="(kind, n) in allKinds"
                      :key="n + kind"
                      :label="kind.name"
                      :value="kind.name"
                      custom
                      :checked.sync="kind.enabled"
                    />
                  </div>
                </CCol>
              </CRow>
              <!-- #endregion オプション分類 -->

              <!-- #region 質問 -->
              <CRow>
                <CCol class="label"><strong>{{$t("optionEditPage.questions")}}</strong></CCol>
                <CCol class="i-field py-2">
                  <!-- <EnableSelectionList :list="edit.roomTypes" /> -->
                  <EnableSelectionList :list="allQuestions" />
                </CCol>
              </CRow>
              <!-- #endregion 質問 -->

              <!-- #region メディア -->
              <CRow class="mt-5">
                <CCol class="label"><strong class="required-mark">{{
                    $t("optionEditPage.media")
                  }}</strong></CCol>
                <CCol>
                    <!-- #region 画像選択 -->
                    <!-- TODO: 5つまで選択可能にする -->
                    <!-- TODO: 画像の並び替えを可能にする -->
                    <ImageSelectModal
                      :images="images"
                      @selected-images="images = $event"
                      :isDraggable="false"
                      :key="images"
                    />
                    <!-- #endregion 画像選択 -->
                </CCol>
              </CRow>
              <!-- #endregion メディア -->

              <!-- #endregion 入力フィールド -->

              <!-- #region 更新ボタン -->
              <CButton
                class="px-5 mt-4 float-left"
                color="info"
                @click="onUpdateClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.update") }}
              </CButton>
              <!-- #endregion 更新ボタン -->

              <!-- #region 削除ボタン -->
              <CButton
                class="px-5 mt-4 float-right"
                color="danger"
                @click="onDeleteClicked()"
              >
                <i class="cil-trash"></i> {{ $t("common.delete") }}
              </CButton>
              <!-- #endregion 削除ボタン -->
            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton color="secondary" class="" @click="onBackClicked()"
              ><i class="icon cil-arrow-left mr-2"></i
              >{{ $t("common.back") }}</CButton
            >
            <!-- #endregion 戻るボタン -->
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

    <!-- #region Delete confirm -->
    <DeleteConfirmModal
      :deleteConfirmModal="deleteConfirmModal"
      :callback="reqDelete"
      @close="deleteConfirmModal = $event"
    />
    <!-- #endregion Delete confirm -->

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
import axios from "axios";
// import i18n from "@/i18n";
import SuccessModal from "@/components/SuccessModal";
import ErrorModal from "@/components/ErrorModal";
import ImageSelectModal from "@/components/ImageSelectModal.vue";
import DeleteConfirmModal from "@/components/DeleteConfirmModal";
import EnableSelectionList from "@/components/EnableSelectionList";

import {
  required,
  numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

const FEATURE_URL = `/rest/master/feature`;

export default {
  name: "OptionEdit",

  components: {
    SuccessModal,
    ErrorModal,
    ImageSelectModal,
    DeleteConfirmModal,
    EnableSelectionList,
  },

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    enabledQuestions() {
      // return this.edit.questions;
      return this.edit.questions.filter((a) => a.enabled);
    },

    disabledQuestions() {
      // return this.edit.questions;
      return this.edit.questions.filter((a) => !a.enabled);
    },
    showSuccessModal() {
      return this.successModal || this.deleteSuccessModal;
    },
  },
  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
    deleteSuccessModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag

      loading: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,

      //#endregion Flag

      //#region Input
      uniqueId:this.$store.state.uniqueId,
      edit: {},

      //#endregion Input

      /**画面表示用 */
      images: [],
      allKinds:[],
      allQuestions: [],


      errorMessage: "",
    };
  },

  validations() {
    return {
      edit: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        price: {
          required,
          numeric,
        },
        inventory: {
          required,
          numeric,
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
    };
  },

  methods: {
    //#region Event

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },


    /** 削除ボタン押下 */
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },


    /** 更新ボタン押下 */
    onUpdateClicked() {
      this.$v.edit.$invalid ? this.$v.edit.$touch() : this.reqPut();
    },

    //#endregion Event

    //#region Request
    /**
     * get option detail
     */
    reqGet() {
      this.loading = true;
      const optionId = this.$route.params.optionId;
      console.log("reqGet")
      console.log(optionId)
      const url = `/rest/hotel/${this.uniqueId}/option/${optionId}`;
      axios
      .get(url, {
        headers: { "Accept-Language": this.$i18n.locale },
      })
      .then((resp) => {
        const data = resp.data;
        console.log(data);

        this.dataInsert(data);

        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },

     /**data set */ 
    dataInsert(data){
        this.edit = {};
        this.images=[];

        //TODO:開発用（オプションとrelationしているオプション分類）
        const kinds = [
            { categoryId:83, name:"オプション分類01" , description:"説明1"},
            { categoryId:84, name:"オプション分類02" , description:"説明2"},
            { categoryId:85, name:"オプション分類03" , description:"説明3"},
          ];

        //TODO:開発用（オプションとrelationしている質問の値）
        const questions = [
            { questionId: 1 , questionText:"質問1" , answerText:"答え1"},
            { questionId: 2 , questionText:"質問2" , answerText:"答え2"},
            { questionId: 3 , questionText:"質問3" , answerText:"答え3"}
          ];

        this.images = data.images
        this.edit = {
          id : data.id,
          name : data.name,
          description: data.description,
          price : data.price,
          inventory : data.inventory,
          // TODO:開発用
          status : false,
          // TODO:開発用
          // kinds : data.kinds
          kinds : [],
          questions : []
        }

        // TODO:開発用
        kinds.forEach((a)=>{
          this.edit.kinds.push(
            {
              id:a.categoryId,
              name:a.name,
              enabled:false
            }
          )
        });
        
        // TODO:開発用
        questions.forEach((a)=>{
          this.edit.questions.push(
            {
              id:a.questionId,
              name:a.questionText,
              enabled:false
            }
          )
        });

        console.log(this.images)

        this.reqGetAllOptionKinds();
    },



     reqGetAllOptionKinds() {
      // NOTE:宿特徴のtype=13はオプション分類
      const url = `${FEATURE_URL}?type=13`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((resp) => {
          const datas = resp.data.features;
          console.log(datas);

          this.createChekBoxObj(datas);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          this.loading = false;
        });
    },

    /**
     * create chekbox object
     */
    createChekBoxObj(datas){
      this.allKinds = [];
      datas.forEach((data) => {
        this.allKinds.push(
          {
            id: data.id,
            name: data.name,
            enabled: false
          }
        );
      });
      this.existKinds(this.allKinds);
    },

    /**
     * exist kinds id check
     */
    existKinds(datas){
      datas.forEach((a)=>{
        this.edit.kinds.forEach((b)=>{
          if (a.id === b.id) {
             a.enabled = true;
           }
        })
      });
      this.reqGetAllQuestions();
    },

    /**
     * get all questions
     */
      reqGetAllQuestions() {
        // TODO:開発用
        // const responseData = {
        //   language:"ja",
        //   list:[
        //     {
        //       id:1,
        //       hotelId:"3922230201001328",
        //       questionText:"質問1",
        //       answerText: "答え1",
        //       displayOrder: 1,
        //       status:100,
        //     },
        //     { 
        //       id:2,
        //       hotelId:"3922230201001328",
        //       questionText:"質問2",
        //       answerText: "答え2",
        //       displayOrder: 2,
        //       status:100,
        //     },
        //     { 
        //       id:3,
        //       hotelId:"3922230201001328",
        //       questionText:"質問3",
        //       answerText: "答え3",
        //       displayOrder: 3,
        //       status:100,
        //     },
        //     { 
        //       id:4,
        //       hotelId:"3922230201001328",
        //       questionText:"質問4",
        //       answerText: "答え4",
        //       displayOrder: 4,
        //       status:100,
        //     },
        //     { 
        //       id:5,
        //       hotelId:"3922230201001328",
        //       questionText:"質問5",
        //       answerText: "答え5",
        //       displayOrder: 5,
        //       status:100,
        //     }
        //    ]
        // }
        // const datas = responseData;
        // this.createSelectListObj(datas)
      const url = `/rest/hotel/${this.uniqueId}/question`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$keycloak.token}`,
            "Accept-Language": this.$i18n.locale,
          },
        })
        .then((resp) => {
          const datas = resp.data.list;
          console.log(datas);

          this.createSelectListObj(datas);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.errorModal = true;
          this.errorMessage = `${err.name} : ${err.message}`;
          this.loading = false;
        });
    },

    createSelectListObj(datas){
      this.allQuestions = [];
      datas.forEach((data) => {
        this.allQuestions.push(
          {
            id: data.id,
            name: data.questionText,
            enabled: false
          }
        );
      });
      this.existQuestions(this.allQuestions);
    },


    /**
     * exist question id  check
     */
      existQuestions(datas){
      datas.forEach((a)=>{
        this.edit.questions.forEach((b)=>{
          if (a.id === b.id) {
             a.enabled = true;
           }
        })
      })
    },

    /**
     * update
     */
    reqPut() {
      this.loading = true;

      const optionId = this.$route.params.optionId;
      const url = `/rest/hotel/${this.uniqueId}/option/${optionId}`;
      const body = {
        images : this.images,
        name : this.edit.name,
        description: this.edit.description,
        price : this.edit.price,
        inventory : this.edit.inventory,
        questions:[]
      }
      axios
      .put(url,body,
          {headers: { "Accept-Language": this.$i18n.locale },}
        )
      .then((resp) => {
        const data = resp.data;
        console.log(data);
        
        this.reqGet();

        this.loading = false;
        this.successModal = true;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },
    
     /**
     * delete 
     */
    reqDelete() {
      this.loading = true;
      this.edit = {};
      this.images = [];
      this.allKinds = [];
      this.allQuestions = [];

      this.deleteConfirmModal = false;

      const optionId = this.$route.params.optionId;
      const url = `/rest/hotel/${this.uniqueId}/option/${optionId}`;
      
      axios
      .delete(url,
          {headers: { "Accept-Language": this.$i18n.locale },}
        )
      .then((resp) => {
        console.log(resp.data);

        this.loading = false;
        this.successModal = true;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
    },

    //#region Method

    /**
     * save tab no for page reload
     */


    backPage() {
      this.$router.go(-1);
    },

    //#endregion Method
  },
  beforeMount(){
    this.reqGet();
  }
};
</script>
<style>
.option_question_list {
  height: 200px;
  overflow: auto;
  border-radius: 0px !important;
}

.list-box {
  border: 1px solid;
  border-color: #cfd5df;
  padding: 0;
}

.list-header {
  border-radius: 0px !important;
}

.positioin-center {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sizeBg {
  height: 4rem;
  font-size: 3.5rem !important;
}
</style>
