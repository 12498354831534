<template>
  <div>

    <Draggable
      class="img_list img-drg"
      v-model="showImagesList"
      @change="onSortEnd"
      v-bind="dragOptions"
      tag="ul"
      v-if="!isImage"
      :disabled="isDraggable"
    >
      <!-- <ul class="img_list"> -->
      <li
        v-for="image in showImagesList"
        :key="image.fileName"
      ><span
          class="image-lists"
          @click="selectImgL(image)"
          v-lazy:background-image="imgMd+image.fileName"
        >
          <div
            class="image-container"
            v-if="image.title"
          >
            <strong class="img-title">{{image.title}}</strong>
          </div>
        </span></li>
      <!-- </ul> -->
    </Draggable>
    <CSpinner
      color="info"
      class="mx-auto d-block my-auto mx-5"
      v-if="beforLoadImg"
    />
    <CButton
      color="dark"
      v-if="!isDraggable"
      @click="openSelectGallery()"
    >{{$t('imageGallery.imageSelect')}}</CButton>
    <CModal
      color="info"
      size="xl"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="galleryModal"
    >
      <template #header>
        <h4 class="modal-title">{{$t('common.edit')}}</h4>
      </template>
      <div class="category_section">
        <ul>
          <li>
            <strong>{{ $t("imageGallery.selectCategory") }}</strong>
          </li>
          <li>
            <CSelect
              :options="CategoryList"
              :value.sync="selectedCategory"
              @change="getImgByCategory($event)"
            />
          </li>
        </ul>
      </div>
      <div class="image_gallery_modal">
        <ul
          class="img_list"
          v-if="!isImage"
        >
          <li
            v-for="image in imageGallery"
            :key="image.fileName"
          ><span
              :class="image.selected?'sel image-lists':'unc image-lists'"
              @click="selectImgL(image)"
              v-lazy:background-image="imgMd+image.fileName"
            >
              <CInputCheckbox :checked.sync="image.selected" />
              <div
                class="image-container"
                v-if="image.title"
              >
                <strong class="img-title">{{image.title}}</strong>
              </div>
            </span></li>
        </ul>
        <transition name="fade">
          <span
            color="danger"
            class="limit-msg border-white"
            v-if="alertMsg"
          >
            {{$t('commonMessage.maxImageSelectLimit')}}
          </span>
        </transition>
        <CSpinner
          color="info"
          class="mx-auto d-block my-auto mx-5"
          v-if="beforLoadImg"
        />
        <CAlert
          class="mx-5 my-3 text-center"
          color="warning"
          v-if="noImage"
        >{{$t('imageGallery.noImage')}}</CAlert>
        <!-- <pre><code>{{imageGallery}}</code></pre> -->
      </div>

      <template #footer>
        <CButton
          @click="cloaseGallery()"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>

        <CButton
          @click="submitSelectedImg()"
          color="success"
        >
          <i class="icon cil-save"></i> {{ $t("common.update") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";

export default {
  props: {
    images: {
      type: Array,
    },
    imgCategory: {
      type: Number,
    },
    isDraggable: {
      type: Boolean,
    },
    selectLmt: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      galleryModal: false,
      beforLoadImg: false,
      noImage: false,
      alertMsg: false,
      imageGallery: [],
      imgUrl: config.IMAGE_URL,
      imgMd: config.IMAGE_MD_URL, // medium size
      test: "",
      CategoryList: [],
      selectedCategory: 0,
      testv: "",
      showImagesList: [],
    };
  },
  created() {
    this.gUniqueId = this.$store.state.uniqueId;
  },
  computed: {
    isImage() {
      return this.imageGallery === undefined || this.imageGallery.length == 0;
    },
    // showImages() {
    //   var allImage = this.images;
    //   return allImage.sort((a, b) => a.displayOrder - b.displayOrder);
    // },
    /** drag and drop */
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    isValidSelect() {
      this.selectLmt ? this.selectLmt : this.imageGallery.length + 1;
      var countSelected = this.imageGallery.filter((e) => e.selected);
      return !(countSelected.length > this.selectLmt - 1);
    },
  },
  updated() {
    if (this.alertMsg) setTimeout(() => (this.alertMsg = false), 1000);
  },
  methods: {
    /**
     * get all imgaes
     *
     */
    getAllImagelist() {
      this.imageGallery = [];
      this.beforLoadImg = true;
      var urlRequest = "/rest/hotel/" + this.gUniqueId + "/image";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          var allImagesList = resp.data.images;
          allImagesList.map((a) =>
            this.imageGallery.push({
              fileName: a.fileName,
              selected: false,
              category: a.category,
              displayOrder: a.displayOrder,
              title: a.title,
            })
          );
          if (this.galleryModal)
            this.imageGallery.forEach((e) => {
              this.images.forEach((a) => {
                if (e.fileName == a.fileName) {
                  e.selected = true;
                }
              });
            });
          this.imageGallery.sort((a, b) => a.displayOrder - b.displayOrder);
          console.log(this.imageGallery);
          this.beforLoadImg = false;
          this.imageGallery.length <= 0
            ? (this.noImage = true)
            : (this.noImage = false);
        })
        .catch((err) => {
          console.log(err);
          this.imageGallery = [];
          this.beforLoadImg = false;
          this.noImage = true;
        });
    },

    /** Dialog
     *  image select from gallery */
    openSelectGallery() {
      this.galleryModal = true;
      // this.getAllImagelist();
      this.triggerImageList();
    },
    /**
     *  select image from gallery
     * */
    selectImgL(name) {
      this.isValidSelect
        ? (name.selected = !name.selected)
        : (name.selected = false)((this.alertMsg = true));
    },
    /** put selected image to the form */
    submitSelectedImg() {
      var returnImages = [];
      this.imageGallery.filter((a) => {
        if (a.selected)
          returnImages.push({
            fileName: a.fileName,
            displayOrder: a.displayOrder,
            title: a.title,
            // description: a.description,
          });
      });
      console.log("all return images:**" + JSON.stringify(returnImages));
      // this.facilityContent.images = returnImages;
      this.$emit("selected-images", returnImages);
      this.galleryModal = false;
      this.imageGallery.forEach((a) => (a.selected = false));
    },
    /** close gallery */
    cloaseGallery() {
      this.galleryModal = false;
      this.imageGallery.forEach((a) => (a.selected = false));
    },
    /** image error */
    replaceByDefault(e) {
      e.target.src = require("../assets/images/error-image.png");
    },
    /**
     *  Get all category list
     */
    getAllCategoryInformation: function () {
      var urlRequest = "/rest/imagecategory";
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          var allCategorys = resp.data.categories;
          this.CategoryList.push({ value: 0, label: this.$t("common.all") });
          allCategorys.map((e) =>
            this.CategoryList.push({
              value: e.id,
              label: e.name,
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * get selected category images
     * */
    getImgByCategory(event) {
      this.testv = event.target.value;
      this.imageGallery = "";
      event.target.value == 0
        ? this.getAllImagelist()
        : this.getDefImg(event.target.value);
    },
    /**
     * set before mounted
     *  */
    getDefImg(catID) {
      this.beforLoadImg = true;

      this.imageGallery = [];
      var urlRequest =
        "/rest/hotel/" + this.gUniqueId + "/image/category/" + catID;
      axios
        .get(urlRequest, {
          headers: { "Accept-Language": this.$i18n.locale },
        })
        .then((resp) => {
          console.log("category image:" + JSON.stringify(resp.data));
          // this.imageGallery = resp.data.images;

          ///

          var allImagesList = resp.data.images;
          allImagesList.map((a) =>
            this.imageGallery.push({
              fileName: a.fileName,
              selected: false,
              category: a.category,
              displayOrder: a.displayOrder,
              title: a.title,
              // description: a.description,
            })
          );
          if (this.galleryModal)
            this.imageGallery.forEach((e) => {
              this.images.forEach((a) => {
                if (e.fileName == a.fileName) {
                  e.selected = true;
                }
              });
            });
          this.imageGallery.sort((a, b) => a.displayOrder - b.displayOrder);
          this.beforLoadImg = false;
          console.log("xxxxxxxxxxxx");
          console.log(this.imageGallery);
          this.imageGallery.length <= 0
            ? (this.noImage = true)
            : (this.noImage = false);
        })
        .catch((err) => {
          console.log(err);
          this.imageGallery = [];
          this.beforLoadImg = false;
          this.noImage = true;
        });
    },
    triggerImageList() {
      this.imgCategory
        ? (this.getDefImg(this.imgCategory),
          (this.selectedCategory = this.imgCategory))
        : this.getAllImagelist();
    },

    /** 一覧表ソート */
    onSortEnd() {
      // console.log(event);

      this.showImagesList.forEach((a, i) => {
        this.showImagesList[i].displayOrder = i;
      });

      // var probBack = [];
      // this.showImagesList.forEach((a) =>
      //   probBack.push({ fileName: a.fileName, displayOrder: a.displayOrder })
      // );

      this.$emit("selected-images", this.showImagesList);
      console.log("Prop back");
    },
  },
  beforeMount() {
    this.getAllImagelist();
    this.getAllCategoryInformation();
    this.showImagesList = this.images;
  },
};
</script>
<style lang="scss">
.img_list {
  li {
    display: inline-block;
    width: 20%;
    margin: 1%;
    // position: relative;
    .image-lists {
      width: 100%;
      padding-top: 56.25%;
      display: block;
      cursor: pointer;
      position: relative;
      // margin: 0 auto 30px;
      background-size: cover;
      background-position: center center;
      transition: 0.7s ease;
      .image-container {
        // transition: 0.5s ease;
        position: absolute;
        width: 100%;
        bottom: 0;
        .img-title {
          text-align: center;
          color: #ffffff;
          font-weight: normal;
          font-size: 0.8em;
          display: block;
          box-shadow: inset 0 0 0 2000px rgba(2, 10, 39, 0.5);
        }
      }
      &:hover {
        box-shadow: inset 0 0 0 2000px rgba(26, 50, 126, 0.3);
      }
    }
    span {
      cursor: pointer;
    }
    width: 23%;
    img {
      width: 100%;
    }
    .unc {
      opacity: 0.7;
    }
    .form-check {
      position: absolute;
      right: 3px;
      top: 3px;
    }
  }
}
.img-drg {
  li {
    .image-lists {
      cursor: move !important;
    }
  }
}
.limit-msg {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f7d6d6;
  border: 1px solid #e28888;
  padding: 1em 2em;
  border-radius: 5px;
  z-index: 2;
}
</style>