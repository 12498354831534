<template>
  <!-- 
    画面: 有効無効選択リスト
    用途: リスト項目の有効無効を設定する
   -->
  <div>
    <!-- #region リスト -->
    <template v-if="isListLabel">
      <h2 class="sub-title mb-3">
        <template v-if="required">
          <strong class="required-mark">{{ listLabel }}</strong>
        </template>
        <template v-else>
          <strong>{{ listLabel }}</strong>
        </template>
      </h2>
    </template>
    <CRow class="mx-2">

      <!-- #region 有効 -->
      <CCol class="list-box">
        <CListGroup>
          <CListGroupItem
            class="list-header text-center py-1"
            color="success"
          >
            <strong>{{ leftListTitle }}</strong>
          </CListGroupItem>
          <CListGroupItem
            class="py-1"
            v-for="(item, i) in enabledList"
            :key="i"
            :action="true"
            @click="toggle(item)"
          >
            {{ item.name }}
          </CListGroupItem>
        </CListGroup>
      </CCol>
      <!-- #endregion 有効 -->

      <CCol
        lg="1"
        class="exchange-mark"
      >
        <i class="positioin-center sizeBg cil-swap-horizontal"></i>
      </CCol>

      <!-- #region 無効 -->
      <CCol class="list-box">
        <CListGroup>
          <CListGroupItem
            class="list-header text-center py-1"
            color="danger"
          >
            <strong>{{ rightListTitle }}</strong>
          </CListGroupItem>
          <CListGroupItem
            class=" py-1"
            v-for="(item, i) in disabledList"
            :key="i"
            :action="true"
            @click="toggle(item)"
          >{{ item.name }}
          </CListGroupItem>
        </CListGroup>
      </CCol>
      <!-- #endregion 無効 -->
    </CRow>
  </div>
</template>

<script>
import i18n from "@/i18n";

export default {
  name: "EnableSelectionList",

  props: {
    /** 表示ラベル */
    listLabel: {
      type: String,
      // required: true,
    },

    /** 必須制約 (省略時は必須対象外) */
    required: {
      type: Boolean,
      default: false,
    },

    /** 有効無効リスト */
    list: {
      type: Array,
    },

    /** 有効無効リスト */
    leftListTitle: {
      type: String,
      default: `${i18n.t("common.valid")}`,
    },

    /** 有効無効リスト */
    rightListTitle: {
      type: String,
      default: `${i18n.t("common.invalid")}`,
    },
  },

  computed: {
    /** 有効リスト */
    enabledList() {
      return this.list && this.list.filter((a) => a.enabled);
    },

    /** 無効リスト */
    disabledList() {
      return this.list && this.list.filter((a) => !a.enabled);
    },

    isListLabel() {
      return this.listLabel != null && this.listLabel.length > 0;
    },
  },

  methods: {
    //#region Method

    /** 有効無効切替 */
    toggle(item) {
      item.enabled = !item.enabled;
    },

    //#endregion Method
  },
};
</script>
<style lang="scss" scoped>
.exchange-mark {
  position: relative;
  i {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>